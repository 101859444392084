
import './style.scss';
import React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Typography, Button, Grid, Divider } from '@mui/material';


const DialogSacPersonalConfirmSubmit = ({
    isOpen,
    setClosedDialog = () => {},
    onConfirmation = () => {}
}) => {
    return (
        <Dialog
            open={isOpen}
            maxWidth='xs'
            fullWidth={true}
            onClose={setClosedDialog}
            PaperProps={{
                className: `dialog-default dialog-sacpersonal-confirmsubmit`
            }}
        >
            <DialogTitle className='dialogtitle-default' sx={{paddingTop: 4}}>
                <Typography variant='subtitle1' className='fontweight-600 textalign-center'>
                    Deseja confirmar o envio da pesquisa de satisfação?
                </Typography>
            </DialogTitle>
                
            <DialogContent className='dialogcontent-default'>
                <Grid container paddingX={2} paddingTop={2}>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
            </DialogContent>
                
            <DialogActions className='dialogactions-default' sx={{paddingX: 4, paddingBottom: 4, justifyContent: 'space-between'}}>
                <Button
                    type='button'
                    className={`borderradius-default background-grey-3 color-black`}
                    size='small'
                    onClick={setClosedDialog}
                >
                    Não
                </Button>
                    
                <Button
                    type='button'
                    className='background-red color-white borderradius-default'
                    size='small'
                    onClick={onConfirmation}
                >
                    Sim
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogSacPersonalConfirmSubmit;