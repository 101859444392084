
import './style.scss';

import React from 'react';
import { TextField } from '@mui/material';
import IDefaultTextFieldProps from './ITextFieldProps';

const DefaultTextField: React.FC<IDefaultTextFieldProps> = ({
    label = '',
    name = '',
    color = "primary",
    margin = "normal",
    disabled = false,
    readonly = false,
    helperText = undefined,
    placeholder = undefined,
    required = true,
    variant = 'outlined',
    value = undefined,
    type = 'text',
    maxlength = 9999,
    onChange = (e) => {},
    onBlur = () => {},
    onKeyUp = (e) => {},
    onKeyDown = (e) => {},
    onKeyPress = (e) => {},
    multiline = false,
    rows = 0,
    minRows = 0,
    maxRows = 0,
    className = '',
    size = 'medium'
}) => {
    return (
        <TextField
            label={label}
            name={name}
            disabled={disabled}
            color={color}
            fullWidth={true}
            helperText={helperText}
            margin={margin}
            placeholder={placeholder}
            required={required}
            variant={variant}
            value={value}
            type={type}
            autoComplete={'off'}
            onChange={onChange}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress}
            inputProps={{
                maxLength: maxlength,
                readOnly: readonly
            }}
            multiline={multiline}
            rows={rows}
            minRows={minRows}
            maxRows={maxRows}
            size={size}

            className={`textfield-default ${multiline ? 'multiline' : ''} ${className}`}
        />
    );
}

export default DefaultTextField;