

const Normalize = (string) => {
    return string.trim().toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}


const StringHelper = {
    Normalize
}

export default StringHelper;