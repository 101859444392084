import React, { useContext, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import classnames from "classnames";
import { HorizontalBar } from "react-chartjs-2";

import { UserContext } from "../../../components/Contexts/UserContext";
import useStoreEvaluation from "../../../hooks/useStoreEvaluation";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { HelpOutline } from "@mui/icons-material";

export default function StoreEvaluation({
  activeNav,
  onNavChange,
  regions,
  region,
  onRegionChange,
  evalComparative,
  evalRegional,
  evalStore,
}) {
  const { user } = useContext(UserContext);
  const { chartStoreCompare } = useStoreEvaluation();

  const [showCompareDropdown, setShowCompareDropdown] = useState(false);

  const handleRegionChange = (nav, region) => {
    onNavChange(nav);
    onRegionChange(region);
  };

  return (
    <Card className="shadow borderradius-default">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <div style={{ display: "inline-block", marginRight: "10px" }}>
              <h6 className="text-uppercase text-light ls-1 mb-1">
                Como está a sua Loja
              </h6>
              <h2 className="mb-0">Comparativo</h2>
            </div>
            <div style={{ display: "inline-block" }}>
              <Tooltip
                title={
                  <>
                    <Typography
                      variant="subtitle2"
                      className="background-red-2 color-white infonps-title"
                      padding={1}
                    >
                      DETALHES DO COMPARATIVO
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      className="color-white fontsize-12"
                      paddingX={2.5}
                      paddingY={1.5}
                    >
                      O Comparativo ilustra em % o desempenho do questionário da
                      revenda/reformadora nos três critérios de análise (barras
                      superiores) comparado com o desempenho da sua regional ou
                      nacional (barras inferiores).
                    </Typography>
                  </>
                }
                arrow
                componentsProps={{
                  tooltip: {
                    className: "infonps-tooltip background-red",
                  },
                  arrow: {
                    className: "color-red-2",
                  },
                }}
              >
                <IconButton
                  className="iconbutton-info background-red color-white"
                  size="small"
                >
                  <HelpOutline fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <div className="col text-right">
            {user.profile.id === 6 ? (
              <Nav className="justify-content-end" pills>
                <NavItem>
                  <NavLink
                    className={`${classnames("py-2 px-3", {
                      active: activeNav === 1,
                    })} borderradius-default`}
                    onClick={() => handleRegionChange(1, "Regional")}
                    style={{ cursor: "pointer" }}
                  >
                    <span className="d-none d-md-block">Regional</span>
                    <span className="d-md-none">R</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${classnames("py-2 px-3", {
                      active: activeNav === 2,
                    })} borderradius-default`}
                    data-toggle="tab"
                    onClick={() => handleRegionChange(2, "Nacional")}
                    style={{ cursor: "pointer" }}
                  >
                    <span className="d-none d-md-block">Nacional</span>
                    <span className="d-md-none">N</span>
                  </NavLink>
                </NavItem>
              </Nav>
            ) : (
              <Dropdown
                isOpen={showCompareDropdown}
                toggle={() => setShowCompareDropdown(!showCompareDropdown)}
                className={classnames("py-2 px-3", {
                  active: activeNav === 2,
                })}
                data-toggle="tab"
                href="#pablo"
              >
                <DropdownToggle caret>{region.name}</DropdownToggle>
                <DropdownMenu>
                  {regions.map((item, key) => (
                    <DropdownItem
                      key={key}
                      active={region.id === item.id}
                      onClick={() => handleRegionChange(1, item)}
                    >
                      <span>{item.name}</span>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
        </Row>
      </CardHeader>

      <CardBody>
        {/* Chart */}
        <div className="chart">
          <HorizontalBar
            data={chartStoreCompare({
              activeNav,
              evalComparative,
              evalRegional,
              evalStore,
              region,
            })}
            options={{
              legend: {
                display: false,
              },
              scales: {
                xAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      min: 0,
                      max: 100,
                    },
                  },
                ],
              },

              responsive: true,
              maintainAspectRatio: false,
            }}
          />
        </div>
      </CardBody>
    </Card>
  );
}
