import Axios from "axios";
import BaseUrl from "../variables/api";

const FindByCode = async (code) => {
  return Axios.get(`${BaseUrl}/peb/clients/${code}`);
};

const SaveSubscription = async (formData) => {
  return Axios.post(`${BaseUrl}/peb/register`, Object.fromEntries(formData));
};

const Subscription = async (client) => {
  const authToken = localStorage.getItem("banana");
  return Axios.post(`${BaseUrl}/peb/subscription`, client, {
    headers: {
      Authorization: "Bearer " + authToken,
    },
  });
};

const ClientsService = {
  FindByCode,
  SaveSubscription,
  Subscription,
};

export default ClientsService;
