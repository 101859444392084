import EmblemPDV from "../assets/img/emblems/EmblemasPEB2024_PDV.png";
import EmblemGoDigital from "../assets/img/emblems/EmblemasPEB2024_BUSINESS.png";
import EmblemAtendimento from "../assets/img/emblems/EmblemasPEB2024_ATENDIMENTO.png";
import EmblemPesquisa from "../assets/img/emblems/EmblemasPEB2024_PESQUISADESATISFAÇÃO.png";
import EmblemNPS from "../assets/img/emblems/EmblemasPEB2024_NPS.png";
import EmblemPontuacaoExtra from "../assets/img/emblems/EmblemasPEB2024_PONTUAÇÕESEXTRAS.png";
import EmblemTreinamento from "../assets/img/emblems/EmblemasPEB2024_TREINAMENTO.png";
import EmblemSellOut from "../assets/img/emblems/EmblemasPEB2024_SELLOUT.png";
import EmblemQuestionarioBandag from "../assets/img/emblems/EmblemasPEB2024_QUESTIONÁRIO.png";
import EmblemGMB from "../assets/img/emblems/EmblemasPEB2024_GOOGLE.png";
import EmblemInativo from "../assets/img/emblems/EmblemasPEB2024_-.png";
// import EmblemPerfilLoja from "../assets/img/emblems/perfil_loja.jpg";

export const EmblemImages = {
  pdv: EmblemPDV,
  godigital: EmblemGoDigital,
  atendimento: EmblemAtendimento,
  pesquisa_satisfacao: EmblemPesquisa,
  nps: EmblemNPS,
  pontuacoes_extras: EmblemPontuacaoExtra,
  treinamento: EmblemTreinamento,
  leitura_sell_out: EmblemSellOut,
  // perfil_loja: EmblemPerfilLoja,
  questionario_bandag: EmblemQuestionarioBandag,
  gmb: EmblemGMB,
  inativo: EmblemInativo,
};
