import React from "react";
import { Alert } from "reactstrap";

export default function ({ text }) {
  return (
    <Alert color="danger" 
      style={{
        position: "fixed",
        top: "30px",
        zIndex: 9999
      }}
    >
      {text}
    </Alert>
  );
}
