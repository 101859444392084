/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import AuthFooter from "../components/Footers/AuthFooter.jsx";

import routes from "../routes.js";

class Auth extends React.Component {
  componentDidMount() {}
  componentWillUnmount() {}
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <>
        <div className="main-content">
          <div
            className="header header-gradient py-8 py-lg-8"
            // style={{
            //   background:
            //     "url(" +
            //     require("assets/img/brand/excelencia-logo.png") +
            //     ") top center / 10% no-repeat, linear-gradient(87deg, #ed1c24 0, #ae0e14 100%)"
            // }}
          >
            {/* <Container>
              <div className="header-body text-center mb-3">
                <Row className="justify-content-center">
                  <Col lg="2" md="3" xs="4">
                    <img
                      src={require("assets/img/brand/excelencia-logo.png")}
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
              </div>
            </Container> */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>
          {/* Page content */}
          <Container className="mt--9 pb-5">
            <Row className="justify-content-center">
              <Switch>{this.getRoutes(routes)}</Switch>
            </Row>
          </Container>
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Auth;
