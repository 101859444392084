import React from "react";
import { Fragment } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

export default function PaginationControls ({ totalItems, itemsPerPage, currentPage, setCurrentPage }) {
  const totalPages = () => {
    const totalPages = totalItems <= itemsPerPage ? 1 : Math.ceil(totalItems / itemsPerPage);
    return totalPages;
  }

  const previousPage = () => {
    const prevPage = currentPage - 1 ? currentPage - 1 : currentPage;
    setCurrentPage(prevPage);
  }

  const nextPage = () => {
    let page = currentPage || 1,
    nxtPage = (totalPages() > page) ? page + 1 : page;
    setCurrentPage(nxtPage);
  }

  const renderPageItems = () => {
    const ttPages = totalPages();
    const pages = [];

    const leftPages = currentPage - 2 > 0
      ? currentPage - 2
      : 1;
    const rightPages = currentPage + 2 <= ttPages
      ? currentPage + 2
      : ttPages;

    for (let page = leftPages; page <= currentPage - 1; page++) {
      pages.push(renderPageItem(page, false));
    }

    pages.push(renderPageItem(currentPage, true));

    for (let page = currentPage + 1; page <= rightPages; page++) {
      pages.push(renderPageItem(page, false));
    }

    return (
      <>
        {pages.map((page, key) => (
          <Fragment key={key}>
            {page}
          </Fragment>
        ))}
      </>
    );
  }

  const renderPageItem = (page, active) => {
    return (
      <PaginationItem className={active ? "active" : ""}>
        <PaginationLink
          href="#pablo"
          onClick={(e) => {
            e.preventDefault();
            setCurrentPage(page);
          }}
        >
          {page}
        </PaginationLink>
      </PaginationItem>
    )
  }

  return (
    <Pagination
      className="pagination justify-content-end mb-0"
      listClassName="justify-content-end mb-0"
    >
      <PaginationItem className={currentPage <= 1 ? "disabled" : ""}>
        <PaginationLink
          href="#pablo"
          onClick={(e) => {
            e.preventDefault();
            previousPage();
          }}
          tabIndex="-1"
        >
          <i className="fas fa-angle-left" />
          <span className="sr-only">Previous</span>
        </PaginationLink>
      </PaginationItem>
      {renderPageItems()}
      <PaginationItem className={currentPage >= totalPages() ? "disabled" : ""}>
        <PaginationLink
          href="#pablo"
          onClick={(e) => {
            e.preventDefault();
            nextPage();
          }}
        >
          <i className="fas fa-angle-right" />
          <span className="sr-only">Next</span>
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
}
