import "./style.scss";
import React, { useContext } from "react";

import { UserContext } from "../Contexts/UserContext";
import { StoreContext } from "../Contexts/StoreContext";
import { Button, Grid, Typography } from "@mui/material";
import BandagIcon from "../Icons/BandagIcon";
import TruckIcon from "../Icons/TruckIcon";
import CarIcon from "../Icons/CarIcon";
import StoreContainerTrigger from "../StoreContainer/StoreContainerTrigger";
import NotificationsTrigger from "../Notifications/NotificationsTrigger";

export default function Filters({
  showCategories = true,
  showStoreContainerTrigger = true,
  showNotificationsTrigger = true,
}) {
  const { user } = useContext(UserContext);
  const {
    store,
    setStore,
    setStoreCode,
    selectedCategory,
    setSelectedCategory,
    consolidatedRegion,
    hasCarCategory,
    hasTBRCategory,
    hasBDGCategory,
  } = useContext(StoreContext);

  const handleCategoryChange = (category) => {
    if (store) {
      let found = user.clients.find(
        (s) =>
          s.code === store.code &&
          s.categories.find((c) => c.descr_pt === category)
      );

      if (found) {
        setStore(found);
        setStoreCode(found.code);
      } else if (!found && (!consolidatedRegion || !consolidatedRegion.id)) {
        found = user.clients.find((s) =>
          s.categories.find((c) => c.descr_pt === category)
        );

        setStore(found);
        setStoreCode(found.code);
      } else {
        setStore(null);
        setStoreCode(0);
      }
    } else {
      setStore(null);
      setStoreCode(0);
    }

    setSelectedCategory(category);
  };

  return (
    <>
      <Grid container spacing={4} className="filters-container">
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="subtitle2" className="fontweight-600">
              Programa de Excelência Bridgestone
            </Typography>
          </Grid>

          <Grid item className="triggers-container">
            {showNotificationsTrigger && (
              <NotificationsTrigger onHeader={false} />
            )}

            {showStoreContainerTrigger && (
              <StoreContainerTrigger onHeader={false} />
            )}
          </Grid>
        </Grid>

        <Grid item lg={6} xs={12}>
          <Typography variant="h5" className="fontweight-600">
            Excelente dia, time!
          </Typography>
        </Grid>

        {showCategories && (
          <Grid item lg={6} xs={12} className="box-buttons">
            {hasCarCategory && (
              <Button
                className={`category-button ${
                  selectedCategory === "CAR" ? "selected" : ""
                }`}
                onClick={() => handleCategoryChange("CAR")}
                startIcon={<CarIcon className="category-icon" />}
              >
                Car
              </Button>
            )}

            {hasTBRCategory && (
              <Button
                className={`category-button ${
                  selectedCategory === "TRUCK" ? "selected" : ""
                }`}
                onClick={() => handleCategoryChange("TRUCK")}
                startIcon={<TruckIcon className="category-icon" />}
              >
                Truck
              </Button>
            )}

            {hasBDGCategory && (
              <Button
                className={`category-button ${
                  selectedCategory === "BANDAG" ? "selected" : ""
                }`}
                onClick={() => handleCategoryChange("BANDAG")}
                startIcon={<BandagIcon className="category-icon" />}
              >
                Bandag
              </Button>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
}
