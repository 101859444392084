import './questionnaireSubmissionStatus.scss';

import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, Box, Button, IconButton } from '@mui/material';
import Loader from '../Bridgestone/Loader';
import { StoreContext } from '../Contexts/StoreContext';
import AdvisorList from '../AdvisorList';
import { KeyboardArrowDown } from '@mui/icons-material';
import { UserContext } from '../Contexts/UserContext';
import SubmissionService from '../../services/SubmissionService';
import FileSaver from 'file-saver';


const defaultQuestionnaireSubmissionStatus = {
    sent: 0,
    pending: 0,
    linkedCustomers: 0,
    pendingQuestionnaires: 0,
    expirationDate: undefined
};


const QuestionnaireSubmissionStatus = ({
    containerProps = {
        className: 'shadow',
        marginTop: 4
    },
    showRegionsStoresSelect
}) => {
    const { store, storeCode, selectedCategory, consolidatedRegion } = useContext(StoreContext);
    const { user, token } = useContext(UserContext);

    const [data, setData] = useState(defaultQuestionnaireSubmissionStatus);
    const [loading, setLoading] = useState(false);

    const [selectedAvaliationNumber, setSelectedAvaliationNumber] = useState(1);
    const [anchorElAdvisorList, setAnchorElAdvisorList] = useState(null);
    const [selectedAdvisor, setSelectedAdvisor] = useState(undefined);



    useEffect(() => {
        if (!selectedCategory) {
          return;
        }
    
        handleRefresh();
    }, [user, store, storeCode, selectedCategory, consolidatedRegion, selectedAdvisor, selectedAvaliationNumber]);
    


    const handleRefresh = () => {
        const advisorId = selectedAdvisor ? selectedAdvisor.id : undefined;
        SubmissionService.GetStatus(token, selectedCategory, consolidatedRegion.id, advisorId, selectedAvaliationNumber).then((resp) => {
            setData(resp.data);
        });
    }


    const totalQuestions = data.sent + data.pending;
    const sentPercent = totalQuestions == 0 ? 0 : (data.sent / (totalQuestions) * 100).toFixed(0);
    const pendingPercent = (100 - sentPercent).toFixed(0);

    
    const handleDownloadClientList = () => {
        setLoading(true);

        SubmissionService.DownloadClientList(token, selectedCategory)
            .then(resp => {
                const filename = `RelacaoClientes_${selectedCategory}.xlsx`;
                const file = new File([resp.data], filename);

                FileSaver.saveAs(file);
            })
            .finally(() => {
                setLoading(false);
            });
    }


    return (
        <>
            {loading && <Loader />}

            <Grid container {...containerProps} className={`questionnairesubmissionstatus-container ${containerProps.className}`}>
                <Grid item xs={12}>
                    <Grid container spacing={2} alignItems='center'>
                        <Grid item sm={6} xs={12}>
                            <h6 className="text-uppercase text-light ls-1 mb-1"> Questionário Assessor </h6>
                            <h2 className="mb-0">Seu status de envios</h2>
                        </Grid>

                        {/* <Grid item lg={4} xs={6}>
                            {
                                [1, 2].map(avaliation => {
                                    return <Button 
                                        className={`ml-1 mr-1 borderradius-default fontsize-10 ${avaliation == selectedAvaliationNumber ? 'background-primary color-white' : 'color-grey-4'}`} 
                                        onClick={() => setSelectedAvaliationNumber(avaliation)}
                                    >
                                        {avaliation}ª avaliação
                                    </Button>;
                                })
                            }
                        </Grid> */}

                        <Grid item sm={6} xs={12} className='textalign-right'>
                            {
                                showRegionsStoresSelect && <>
                                    <Typography variant="body2">
                                        {consolidatedRegion && consolidatedRegion.id ? consolidatedRegion.name : selectedAdvisor && selectedAdvisor.name || "-"}

                                        <IconButton
                                            className="background-primary color-white ml-2"
                                            size="small"
                                            onClick={(e) => setAnchorElAdvisorList(e.currentTarget)}
                                        >
                                            <KeyboardArrowDown fontSize="small" />
                                        </IconButton>
                                    </Typography>
                                </>
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className='questionnairesubmissionstatus-details' marginTop={2}>
                    <Grid container>
                        <Grid item xs={3} className='questionnairesubmissionstatus-result textalign-center'>
                            <Box marginY={1}>
                                <Typography variant='h4' className='fontweight-600 color-green'>
                                    {data.sent}
                                </Typography>
                            </Box>
                                                
                            <Typography variant='body2' component='h6' className='fontweight-bold color-green'>
                                Enviados
                            </Typography>

                            <Typography variant='caption' component='h6' className=''>
                                {data.linkedCustomers} clientes vinculados
                            </Typography>
                        </Grid>

                        <Grid item xs={3} className='questionnairesubmissionstatus-result textalign-center'>
                            <Box marginY={1}>
                                <Typography variant='h4' className='fontweight-600 color-red'>
                                    {data.pending}
                                </Typography>
                            </Box>
                                                
                            <Typography variant='body2' component='h6' className='fontweight-bold color-red'>
                                Pendentes
                            </Typography>

                            <Typography variant='caption' component='h6' className='color-red'>
                                {data.pendingQuestionnaires} questionários salvos
                            </Typography>
                        </Grid>

                        <Grid item xs={6} className='questionnairesubmissionstatus-result grid-statistic textalign-center'>
                            <Box marginY={1} class='box-percent'>
                                <Box className='box-sent' sx={{ width: `${sentPercent}%` }}> {sentPercent}% </Box>
                                <Box className='box-pending'> {pendingPercent}% </Box>
                            </Box>

                            <Box className='box-legend'>
                                <Typography variant='body2' component='span' className='text-legend-item'> <span className='indicator background-green' /> Enviados </Typography>
                                <Typography variant='body2' component='span' className='text-legend-item'> <span className='indicator background-grey-3' /> Pendentes </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid xs={12} marginTop={2}>
                    <Grid container alignItems='center'>
                        <Grid item md={6} xs={12} marginTop={2}>
                            <Typography variant='caption' component='span' className='fontweight-bold'>Atenção!</Typography>
                            {' '}

                            {
                                Date.parse(data.expirationDate) ? (
                                    <>
                                        <Typography variant='caption' component='span'>O prazo para envio destes questionários é até</Typography>
                                        {' '}
                                        <Typography variant='caption' component='span' className='fontweight-bold'>
                                            {new Date(data.expirationDate).toLocaleDateString()}
                                        </Typography>
                                    </>
                                ) : (
                                    <Typography variant='caption' component='span'>O período para a {selectedAvaliationNumber}ª avaliação ainda não iniciou</Typography>
                                )
                            }
                        </Grid>

                        <Grid item md={6} xs={12} marginTop={2} textAlign='right'>
                            <Button
                                className='background-primary color-white borderradius-default fontsize-12'
                                onClick={handleDownloadClientList}
                            >
                                Baixar relação de clientes
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <AdvisorList
                anchorEl={anchorElAdvisorList}
                handleClose={() => setAnchorElAdvisorList(null)}
                setAdvisor={(advisor) => setSelectedAdvisor(advisor)}
            />
        </>
    );
}


export default QuestionnaireSubmissionStatus;