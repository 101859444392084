
import React from 'react';
import { SvgIcon } from '@mui/material';

const BandagIcon = (props) => {
    return (
        <SvgIcon
            {...props}
            viewBox='0 0 52.87 80'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g>
                <path class="st0" d="M39.35,0.33c-0.02-0.01-0.03-0.02-0.05-0.04l-0.02-0.02c-0.12-0.08-0.25-0.15-0.39-0.2l-0.02-0.01
                    c-0.07-0.02-0.14-0.04-0.2-0.05c-0.01,0-0.02,0-0.04-0.01C38.57,0.01,38.51,0,38.45,0l-0.01,0H15.67c-0.3,0-0.6,0.09-0.85,0.27
                    C14.22,0.69,0,10.98,0,40c0,32.48,14.44,39.58,15.05,39.86c0.2,0.09,0.41,0.14,0.62,0.14h22.76c0.43,0,0.84-0.19,1.12-0.51
                    c1.43-1.34,13.31-13.27,13.31-39.86C52.87,11.66,39.91,0.78,39.35,0.33z M10.53,14.27C7.37,21.58,5.77,30.23,5.77,40
                    c0,10.46,1.49,19.34,4.44,26.39c2.25,5.39,4.94,8.7,7,10.64h-1.16c-1-0.59-4.12-2.73-7.09-8.09c-3.98-7.17-6-16.91-6-28.95
                    c0-10.67,2.01-19.92,5.97-27.49c3-5.73,6.2-8.67,7.25-9.54h1.59C15.69,5.16,12.91,8.78,10.53,14.27z M15.64,14.27
                    c-3.16,7.31-4.77,15.96-4.77,25.73c0,10.46,1.49,19.34,4.44,26.39c2.25,5.39,4.94,8.7,7,10.64h-1.58
                    c-1.33-0.92-4.54-3.56-7.38-9.21C9.78,60.7,7.97,51.34,7.97,40c0-10.3,1.87-19.31,5.56-26.78c2.89-5.85,6.11-9.07,7.46-10.26h1.91
                    C20.8,5.16,18.02,8.78,15.64,14.27z M21.03,14.27c-3.16,7.31-4.77,15.96-4.77,25.73c0,10.46,1.49,19.34,4.44,26.39
                    c2.25,5.39,4.94,8.7,7,10.64h-1.85c-1.33-0.92-4.54-3.56-7.38-9.21C14.9,60.7,13.08,51.34,13.08,40c0-10.3,1.87-19.31,5.56-26.78
                    c2.89-5.85,6.11-9.07,7.46-10.26h2.18C26.19,5.16,23.41,8.78,21.03,14.27z M26.54,14.77c-3.43,7.37-5.17,15.85-5.17,25.23
                    c0,21.86,7.58,32.44,12.57,37.04h-2.7c-1.34-0.92-4.54-3.56-7.38-9.21C20.28,60.7,18.47,51.34,18.47,40
                    c0-10.3,1.87-19.31,5.56-26.78c2.89-5.85,6.11-9.07,7.46-10.26h3.05C32.23,5.32,29.16,9.16,26.54,14.77z M49.9,39.63
                    c0,9.82-1.7,18.67-5.05,26.3c-2.55,5.81-5.38,9.3-6.66,10.7c-1.52-1.05-4.86-3.78-7.87-9.21c-3.97-7.17-5.98-16.4-5.98-27.43
                    c0-22.24,10.41-33.37,14.01-36.54c1.3,1.35,4.11,4.69,6.62,10.32C48.24,21.16,49.9,29.85,49.9,39.63z M38.77,0.4L38.77,0.4
                    L38.77,0.4L38.77,0.4z M37.1,19.97c-10.65,20.67-0.07,39.21,0.04,39.4c0.27,0.45,0.76,0.73,1.28,0.73h0.01h0
                    c0.53-0.01,1.02-0.3,1.28-0.76c0.11-0.19,10.62-19.54,0.01-39.39c-0.26-0.48-0.76-0.78-1.32-0.78
                    C37.86,19.17,37.36,19.48,37.1,19.97z M38.44,24.09c5.7,13.09,2.13,25.53-0.05,31.01C36.2,49.8,32.65,37.67,38.44,24.09z"/>
            </g>
        </SvgIcon>
    );
}

export default BandagIcon;