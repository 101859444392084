import React from "react";
import { Spinner } from "reactstrap";

const Loader = (props) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,0.7)",
        width: "100%",
        height: "100%",
        zIndex: "999999",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{}}>
        <Spinner type="grow" color="primary" />
        <Spinner type="grow" color="danger" />
        <Spinner type="grow" color="primary" />
        <Spinner type="grow" color="danger" />
        <Spinner type="grow" color="primary" />
        <Spinner type="grow" color="danger" />
      </div>
    </div>
  );
};

export default Loader;
