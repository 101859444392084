
import React from 'react';
import { SvgIcon } from '@mui/material';

const TruckIcon = (props) => {
    return (
        <SvgIcon
            {...props}
            viewBox='0 0 80 56.71'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path class="st0" d="M80,30.05v-0.03c0-0.01,0-0.01,0-0.02c0-0.07-0.01-0.14-0.02-0.21c0-0.01,0-0.02,0-0.04
                c-0.01-0.08-0.03-0.15-0.05-0.22c0,0,0-0.01,0-0.02l-5.93-19.28c-0.66-2.14-2.6-3.57-4.84-3.57H55.04V1.73
                c0-0.96-0.78-1.73-1.73-1.73H1.73C0.78,0,0,0.78,0,1.73v46.59c0,0.96,0.78,1.73,1.73,1.73h5.1c0.8,3.8,4.18,6.66,8.21,6.66
                s7.41-2.86,8.21-6.66h1.89c0.8,3.8,4.18,6.66,8.21,6.66s7.41-2.86,8.21-6.66h15.2c0.8,3.8,4.18,6.66,8.21,6.66s7.41-2.86,8.21-6.66
                h5.1c0.96,0,1.73-0.78,1.73-1.73V30.05C80,30.05,80,30.05,80,30.05z M75.62,27.31l-10.6-3.53v-7h7.36L75.62,27.31z M34.84,40.07
                h16.73v6.52H41.55C40.85,43.27,38.19,40.68,34.84,40.07z M25.14,46.59h-1.89c-0.7-3.31-3.36-5.91-6.71-6.52h15.3
                C28.5,40.68,25.84,43.27,25.14,46.59z M51.58,3.46V36.6H3.46V3.46H51.58z M3.46,40.07h10.08c-3.35,0.61-6.01,3.2-6.71,6.52H3.46
                V40.07z M15.04,53.24c-2.71,0-4.92-2.21-4.92-4.92c0-2.71,2.21-4.92,4.92-4.92s4.92,2.21,4.92,4.92
                C19.97,51.03,17.76,53.24,15.04,53.24z M33.34,53.24c-2.71,0-4.92-2.21-4.92-4.92c0-2.71,2.21-4.92,4.92-4.92s4.92,2.21,4.92,4.92
                C38.27,51.03,36.06,53.24,33.34,53.24z M64.96,53.24c-2.71,0-4.92-2.21-4.92-4.92c0-2.71,2.21-4.92,4.92-4.92
                c2.71,0,4.92,2.21,4.92,4.92C69.88,51.03,67.67,53.24,64.96,53.24z M73.16,46.59c-0.8-3.8-4.18-6.66-8.21-6.66
                c-4.03,0-7.41,2.86-8.21,6.66h-1.71V10.12h14.11c0.7,0,1.32,0.45,1.52,1.13l0.64,2.06h-8.02c-0.96,0-1.73,0.78-1.73,1.73v9.98
                c0,0.75,0.48,1.41,1.18,1.64l13.79,4.6v3.67h-4.92c-0.96,0-1.73,0.78-1.73,1.73s0.78,1.73,1.73,1.73h4.92v8.18H73.16z" />
        </SvgIcon>
    );
}

export default TruckIcon;