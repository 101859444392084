
import imageCompression from 'browser-image-compression';


const imageExtensions = ["png", "jpg", "jpeg"];
const compressImageOptions = { maxSizeMB: 1, maxWidthOrHeight: 1920, useWebWorker: true };


const GenerateFormData = (event, formData = null) => {
    if (formData == null) {
        formData = new FormData();
    }

    if (event) {
        for (let target of event.target) {
            const name = target.name.trim();
            let value = target.value;

            if (name.length > 0) {
                if (target.type == 'radio' && !target.checked) {
                    continue;
                }  else if (target.type == 'file' && target.files.length > 0) {
                    if (target.multiple) {
                        formData = IncludeFileList(formData, name, target.files);
                        continue;
                    } else {
                        value = target.files[0];
                    }
                }

                IncludeKeyValue(formData, name, value);
            }
        }
    }

    return formData;
}


const IncludeKeyValue = (formData, key, value) => {
    if (formData.has(key)) {
        formData.delete(key);
    }
    
    formData.append(key, value);
    return formData;
}

const IncludeFileList = async (formData, key, fileList) => {
    for (let i = 0; i < fileList.length; i++) {
        let file = fileList[i];
        const fileExtension = file.name.split('.').slice(-1)[0];

        if (imageExtensions.includes(fileExtension)) {
            const compressedImage = await imageCompression(file, compressImageOptions);
            file = new File([compressedImage], file.name, { type: file.type });
        }

        formData = IncludeKeyValue(formData, `${key}[${i}]`, file);
    }

    return formData;
}

const RemoveKey = (formData, key) => {
    formData.delete(key);
    return formData;
}



const ValidateCnpj = (cnpj) => {
    cnpj = cnpj.replace(/([^\d])+/gim, '');

    if (cnpj.length > 0 && cnpj.length == 14) {
        if (cnpj == "00000000000000" || cnpj == "11111111111111" || cnpj == "22222222222222" || cnpj == "33333333333333" || cnpj == "44444444444444" || cnpj == "55555555555555" || cnpj == "66666666666666" || cnpj == "77777777777777" || cnpj == "88888888888888" || cnpj == "99999999999999") {
            return false;
        }


        let length = cnpj.length - 2
        let numbers = cnpj.substring(0, length);
        let digits = cnpj.substring(length);
        let sum = 0;
        let position = length - 7;

        for (let i = length; i >= 1; i--) {
            sum += numbers.charAt(length - i) * position--;

            if (position < 2) {
                position = 9;
            }
        }

        let result = sum % 11 < 2 ? 0 : 11 - sum % 11;

        if (result != digits.charAt(0)) {
            return false;
        }


        length = length + 1;
        numbers = cnpj.substring(0, length);
        sum = 0;
        position = length - 7;

        for (let i = length; i >= 1; i--) {
            sum += numbers.charAt(length - i) * position--;

            if (position < 2) {
                position = 9;
            }
        }

        result = sum % 11 < 2 ? 0 : 11 - sum % 11;


        if (result != digits.charAt(1)) {
            return false;
        }
    } else {
        return false;
    }

    return true;
}

const ValidateCep = (cep) => {
    cep = cep.replace(/([^\d])+/gim, '');
    return cep.length == 8;
}

const ValidatePhone = (phone) => {
    phone = phone.replace(/([^\d])+/gim, '');
    return phone.length == 10 || phone.length == 11;
}

const ValidateEmail = (email) => {
    var regex = /\S+@\S+\.\S+/;
    return regex.test(email);
}


const FormHelper = {
    GenerateFormData,
    IncludeKeyValue,
    RemoveKey,
    IncludeFileList,
    ValidateCnpj,
    ValidateCep,
    ValidatePhone,
    ValidateEmail
}

export default FormHelper;