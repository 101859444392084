import React, { createContext, useState } from "react";

export const UserContext = createContext({});

export default function UserProvider({ children }) {
  const [user, setUser] = useState({
    profile: {
      id: 0,
      name: "",
    },
  });
  const [token, setToken] = useState("");

  return (
    <UserContext.Provider value={{ user, setUser, token, setToken }}>
      {children}
    </UserContext.Provider>
  );
}
