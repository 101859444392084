import "./style.scss";
import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Divider,
} from "@mui/material";
import SelectField from "../SelectField/SelectField";
import FileSaver from "file-saver";
import SatisfactionService from "../../services/SatisfactionService";
import NpsService from "../../services/NpsService";

const currentDate = new Date();

const DialogDownloadNPS = ({
  isOpen,
  setClosedDialog = () => {},
  token,
  store,
  selectedCategory,
  storeCode,
  storeName,
  setLoading,
  setError,
}) => {
  const [data, setData] = useState(currentDate.getFullYear());

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    if (store) {
      NpsService.Download(token, storeCode, selectedCategory, data)
        .then(resp => {
          const filename = `NPS_${storeName.replace(
            / /g,
            ""
          )}.xlsx`;
          const file = new File([resp.data], filename);

          FileSaver.saveAs(file);
        })
        .catch((err) => {
          setError(
            "Não foi possível fazer o download da pesquisa NPS"
          );
        })
        .finally(() => {
          setLoading(false);
          setClosedDialog(false);
        });
    } else {
      NpsService.DownloadAll(token, selectedCategory, data)
        .then(resp => {
          const filename = `NPS_${selectedCategory}.xlsx`;
          const file = new File([resp.data], filename);

          FileSaver.saveAs(file);
        })
        .catch((err) => {
          setError(
            "Não foi possível fazer o download da pesquisa NPS"
          );
        })
        .finally(() => {
          setLoading(false);
          setClosedDialog(false);
        });
    }
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth="xs"
      fullWidth={true}
      onClose={setClosedDialog}
      PaperProps={{
        className: `dialog-default dialog-actionplan-finishtask`,
      }}
    >
      <Box component="form" onSubmit={(e) => handleSubmit(e)}>
        <DialogContent className="dialogcontent-default">
          <Grid container paddingX={2}>
            <Grid item xs={12}>
              <label>Selecione o ano de preenchimento das pesquisas:</label>
              <SelectField
                name="year"
                value={data}
                onChange={(e) => setData(e.target.value)}
                data={[
                  { value: 2022, text: "2022" },
                  { value: 2023, text: "2023" },
                  { value: 2024, text: "2024" },
                ]}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          className="dialogactions-default"
          sx={{
            paddingX: 4,
            paddingBottom: 4,
            justifyContent: "space-between",
          }}
        >
          <Button
            type="button"
            className={`borderradius-default background-grey-3 color-black`}
            size="small"
            onClick={setClosedDialog}
          >
            Cancelar
          </Button>

          <Button
            type="submit"
            className="background-primary color-white borderradius-default"
            size="small"
          >
            Baixar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DialogDownloadNPS;
