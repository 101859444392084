let url = "http://localhost:8080";

if (process.env.REACT_APP_ENV == "production") {
  url = "https://api.sistemasup.com.br";
} else if (process.env.REACT_APP_ENV == "development") {
  url = "https://api-dev.sistemasup.com.br";
} else if (process.env.REACT_APP_ENV == "demo") {
  url = "https://api-demo.sistemasup.com.br";
}

export default url;
