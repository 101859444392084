import Axios from "axios";
import BaseUrl from '../variables/api';

const List = async (token, storeCode, category, taskCategory, region, page = 1, limit = 100) => {
    const params = {
        storeCode: region || !storeCode ? undefined : storeCode,
        category,
        region,
        limit,
        page
    };

    if (taskCategory) {
        params.blockId = taskCategory;
    }

    return Axios.get(`${BaseUrl}/peb/tasks`, {
        headers: {
            Authorization: "Bearer " + token,
        },
        params: params
    });
}

const ListCategories = async (token, category) => {
    return Axios.get(`${BaseUrl}/peb/tasks-categories/${category}`, {
        headers: {
            Authorization: "Bearer " + token,
        }
    })
}

const Done = async (token, idActionPlan, formData) => {
    return Axios.put(`${BaseUrl}/peb/tasks/${idActionPlan}/done`, formData, {
        headers: {
            Authorization: "Bearer " + token,
        }
    });
}

const Reschedule = async (token, idActionPlan, formData) => {
    return Axios.put(`${BaseUrl}/peb/tasks/${idActionPlan}/reschedule`, Object.fromEntries(formData), {
        headers: {
            Authorization: "Bearer " + token,
        }
    })
}

const Cancel = async (token, idActionPlan) => {
    return Axios.put(`${BaseUrl}/peb/tasks/${idActionPlan}/cancel`, {}, {
        headers: {
            Authorization: "Bearer " + token,
        }
    })
}

const Download = async (token, category, code) => {
    return Axios.get(`${BaseUrl}/uptown/reports/questionario-peb?category=${category}&code=${code}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
      responseType: "blob",
    });
}  


const ActionPlanService = {
    List,
    ListCategories,
    Done,
    Reschedule,
    Cancel,
    Download
};

export default ActionPlanService; 