import Axios from "axios";
import BaseUrl from '../variables/api';


const Average = async (token, storeCode, category, region, compare) => {
    return Axios.get(`${BaseUrl}/peb/nps-survey-average`, {
        headers: {
            Authorization: "Bearer " + token,
        },
        params: {
            storeCode: region || storeCode === 0 ? undefined : storeCode,
            category,
            region,
            compare,
        }
    });
}

const Download = async (token, storeCode, category, year) => {
    return Axios.get(`${BaseUrl}/peb/export-nps-surveys/${storeCode}/${category}?year=${year}`, {
        headers: {
            Authorization: "Bearer " + token,
        },
        responseType: 'blob'
    });
}

const DownloadAll = async (token, category, year) => {
    return Axios.get(`${BaseUrl}/peb/export-all-nps-surveys/${category}?year=${year}`, {
        headers: {
            Authorization: "Bearer " + token,
        },
        responseType: 'blob'
    });
}

const NpsService = {
    Average,
    Download,
    DownloadAll
};

export default NpsService; 