import Axios from "axios";
import baseURL from "../variables/api";


const List = async (type, storeCode, storeCategory) => {
    const authToken = localStorage.getItem("banana");
    return await Axios.get(`${baseURL}/peb/notifications/${type}`, {
        headers: {
            Authorization: "Bearer " + authToken,
        }, params: {
            code: storeCode,
            category: storeCategory
        }
    });
}


const Read = async (storeCode, storeCategory) => {
    const authToken = localStorage.getItem("banana");
    return await Axios.get(`${baseURL}/peb/notifications/read`, {
        headers: {
            Authorization: "Bearer " + authToken,
        }, params: {
            code: storeCode,
            category: storeCategory
        }
    });
}



const NotificationsService = {
    List,
    Read
};

export default NotificationsService;