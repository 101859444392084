import Axios from "axios";
import baseURL from "../variables/api";

const GetStatus = async (
  authToken,
  category,
  region,
  advisor,
  assessmentNumber
) => {
  return await Axios.get(`${baseURL}/peb/submissions`, {
    headers: {
      Authorization: "Bearer " + authToken,
    },
    params: {
      category,
      region,
      advisor,
      assessmentNumber,
    },
  });
};

const DownloadClientList = async (authToken, category) => {
  return Axios.post(
    `${baseURL}/peb/submissions/reports/${category}`,
    {},
    {
      headers: {
        Authorization: "Bearer " + authToken,
      },
      responseType: "blob",
    }
  );
};

const SubmissionService = {
  GetStatus,
  DownloadClientList,
};

export default SubmissionService;
