import './style.scss';

import React, { useContext, useEffect, useState } from 'react';
import { Menu, MenuItem, Typography, Box } from "@mui/material";
import { StoreContext } from '../Contexts/StoreContext';
import { UserContext } from '../Contexts/UserContext';
import { Link } from 'react-router-dom';
import NotificationsService from '../../services/NotificationsService';


const Notifications = ({
    anchorEl,
    handleClose,
    setPendingNotificationsCount
}) => {
    const { user } = useContext(UserContext);
    const { storeCode, selectedCategory } = useContext(StoreContext);
    const [notifications, setNotifications] = useState([]);


    useEffect(() => {
        if (Boolean(anchorEl)) {
            handleRead();
        }
    }, [anchorEl]);


    useEffect(() => {
        if (user.profileId === 6 && storeCode && selectedCategory) {
            handleRefresh('new');
        }
        
        if (user.profileId !== 6) {
            handleRefresh('new');
        }
    }, [storeCode, selectedCategory]);


    const handleRefresh = (type) => {
        NotificationsService.List(type, storeCode, selectedCategory)
            .then(resp => {
                const data = resp.data;
                setNotifications(data);

                if (type == 'new') {
                    setPendingNotificationsCount(data.length);
                }
            });
    }


    const handleRead = () => {
        NotificationsService.Read(storeCode, selectedCategory)
            .then(resp => {
                setPendingNotificationsCount(0);
            });
    }

    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
                className: 'notifications-menu',
                sx: {
                    borderRadius: 4,
                    minWidth: 400,
                    maxWidth: 500
                }
            }}
            MenuListProps={{
                sx: {
                    paddingBottom: 0
                }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <Box className='notifications-items'>
                {
                    notifications.length == 0 ? (
                        <Typography variant='body1' padding={2} textAlign='center'>Nenhuma notificação pendente para visualizar</Typography>
                    ) : notifications.map(n => {
                        const createdAt = new Date(n.created_at);

                        const notificationContainer = <MenuItem className='notification-menuitem' key={n.id}>
                            <img src={n.icon} className='notification-icon' alt={n.title} />
                
                            <Box className='notification-text'>
                                <Typography className='notification-title'>{n.title}</Typography>
                                <Typography className='notification-subtitle'>{n.description}</Typography>
                                    
                                <Typography className='notification-createdat'>
                                    Data desta notificação: {createdAt.toLocaleDateString()} - {createdAt.toLocaleTimeString()}
                                </Typography>
                            </Box>
                        </MenuItem>;

                        if (n.session != null && n.session != undefined) {
                            return <Link to={n.session} onClick={handleClose}>
                                {notificationContainer}
                            </Link>;
                        }

                        return notificationContainer;
                    })
                }
            </Box>

            <Box className='notifications-all' onClick={() => handleRefresh('all')}>
                <Typography variant='body2'>Todas notificações</Typography>
            </Box>
        </Menu>
    );
}


export default Notifications;