
import './style.scss';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, DialogActions, Typography, Divider, Button, ImageList } from '@mui/material';
import { Check, PhotoCamera } from '@mui/icons-material';
import FormHelper from '../../helpers/FormHelper';
import RulesAndLicensesService from '../../services/RulesAndLicensesService';
import AttachmentCard from '../AttachmentCard/AttachmentCard';
import useForceRender from '../../hooks/useForceRender';


const DialogRulesAndLicenses = ({
    isOpen,
    setClosedDialog = () => {},
    token,
    storeCode,
    rulesAndLicenses = {},
    readonly = false,
    handleRefresh,
    setLoading,
    setError
}) => {
    const forceRender = useForceRender();

    const [arquivos, setArquivos] = useState([]);
    const uploadInputRef = useRef(null);

    const title = `Normas e Licenças / ${rulesAndLicenses.category || ''}`.toUpperCase();
    const fileUploaded = arquivos && arquivos.length > 0;


    useEffect(() => {
        setArquivos([]);
    }, [rulesAndLicenses]);

    const onSubmit = async (e) => {
        e.preventDefault();

        if (readonly) {
            return;
        }

        if (fileUploaded) {
            setLoading(true);

            let formData = FormHelper.GenerateFormData(e, null);
            formData = await FormHelper.IncludeFileList(formData, 'attachments', arquivos);

            RulesAndLicensesService.Done(token, rulesAndLicenses.id, storeCode, formData)
                .then(resp => {
                    setError(`Card finalizado com sucesso`, 'success');
                    setClosedDialog();
                    handleRefresh();
                }).catch(err => {
                    setError('Não foi possível finalizar o card');
                }).finally(() => {
                    setLoading(false);
                });
        } else {
            uploadInputRef.current && uploadInputRef.current.click();
        }
    }


    const handleAddAttach = (e) => {
        const arquivosAux = arquivos;

        Object.entries(e.target.files).forEach(([key, file]) => {
            arquivosAux.push(file);
        });

        setArquivos(arquivosAux);
        forceRender();
    }

    const handleRemoveAttach = (key) => {
        const arquivosAux = Array.from(arquivos);
        arquivosAux.splice(key, 1);

        setArquivos(arquivosAux);
        forceRender();
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth='xs'
            fullWidth={true}
            onClose={setClosedDialog}
            PaperProps={{
                className: `dialog-default dialog-rulesandlicenses`
            }}
        >
            <Box component='form' onSubmit={onSubmit}>
                <DialogTitle className='dialogtitle-default'>
                    <Typography variant='subtitle2' className='color-white'>
                        {title}
                    </Typography>
                </DialogTitle>
                
                <DialogContent className='dialogcontent-default'>
                    <input
                        ref={uploadInputRef}
                        type="file"
                        style={{
                            display: 'none'
                        }}
                        multiple={true}
                        value=''
                        onChange={handleAddAttach}
                    />

                    <Typography variant='h6' className='fontweight-600' lineHeight={1}>
                        {rulesAndLicenses.title || ''}
                    </Typography>

                    <Box paddingY={3}>
                        <Typography variant='body2'>
                            <span className='fontweight-600'>Descritivo: </span>
                            
                            {
                                (rulesAndLicenses.description || '').split('\n').map((text, index) => {
                                    if (index == 0) {
                                        return text;
                                    }

                                    return <span
                                        key={index}
                                        style={{
                                            display: 'block',
                                            width: '100%',
                                            marginTop: '8px'
                                        }}
                                    >
                                        {text}
                                    </span>;
                                })
                            }
                        </Typography>
                    </Box>

                    {
                        rulesAndLicenses.image && (
                            <Box>
                                <ImageList cols={1}>
                                    <AttachmentCard 
                                        url={rulesAndLicenses.image} 
                                        name={rulesAndLicenses.image}
                                        onClick={() => window.open(rulesAndLicenses.image)} 
                                        showPreviewIcon={true}
                                    />
                                </ImageList>
                            </Box>
                        )
                    }

                    <Box>
                        <ImageList cols={3}>
                            {
                                arquivos.map((arquivo, index) => {
                                    const url = URL.createObjectURL(arquivo);

                                    return <AttachmentCard 
                                        url={url} 
                                        name={arquivo.name} 
                                        onRemove={_ => handleRemoveAttach(index)}
                                        key={index}
                                    />;
                                })
                            }
                        </ImageList>
                    </Box>

                    <Divider />
                </DialogContent>
                
                <DialogActions className='dialogactions-default'>
                    {
                        readonly ? (
                            <Button
                                type='button'
                                className='background-grey-4 color-white borderradius-default'
                                size='small'
                                onClick={setClosedDialog}
                                sx={{
                                    marginX: 'auto'
                                }}
                            >
                                Fechar
                            </Button>
                        ) : (
                            <>
                                <Button
                                    type='button'
                                    className={`color-white borderradius-default ${fileUploaded ? 'background-green' : 'background-red'}`}
                                    size='small'
                                    startIcon={fileUploaded ? <Check fontSize='small' /> : <PhotoCamera fontSize='small' />}
                                    onClick={_ => uploadInputRef.current && uploadInputRef.current.click()}
                                >
                                    {fileUploaded ? `${arquivos.length} arquivos anexados` : 'Anexar comprovante'}
                                </Button>
                                
                                <Button
                                    type='submit'
                                    className='background-green color-white borderradius-default'
                                    size='small'
                                >
                                    Enviar para análise
                                </Button>
                            </>
                        )
                    }
                </DialogActions>
            </Box>
        </Dialog>
    );
}

export default DialogRulesAndLicenses;