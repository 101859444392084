
import './style.scss';
import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, DialogActions, Button, Grid, Divider, TextField } from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ptBR } from 'date-fns/locale';
import FormHelper from '../../helpers/FormHelper'
import ActionPlanService from '../../services/ActionPlanService';

const currentDate = new Date();

const DialogActionPlanRescheduleTask = ({
    isOpen,
    setClosedDialog = () => {},
    token,
    task,
    handleRefresh,
    setLoading,
    setError
}) => {
    const [data, setData] = useState(currentDate);

    useEffect(() => {
        if (task) {
            setData(new Date(task.dueDate));
        } else {
            setData(currentDate);
        }
    }, [task]);

    const handleSubmit = (e) => {
        e.preventDefault();

        setLoading(true);

        let formData = FormHelper.GenerateFormData(e, null);
        formData = FormHelper.IncludeKeyValue(formData, 'date', data.toISOString());

        ActionPlanService.Reschedule(token, task.id, formData)
            .then(resp => {
                setError(`Tarefa reagendada com sucesso`, 'success');
                setClosedDialog();
                handleRefresh();
            }).catch(err => {
                setError('Não foi possível realizar o reagendamento da tarefa');
            }).finally(() => {
                setLoading(false);
            });
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth='xs'
            fullWidth={true}
            onClose={setClosedDialog}
            PaperProps={{
                className: `dialog-default dialog-actionplan-finishtask`
            }}
        >
            <Box component='form' onSubmit={e => handleSubmit(e)}>
                <DialogContent className='dialogcontent-default'>
                    <Grid container paddingX={2}>
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                <StaticDatePicker
                                    displayStaticWrapperAs="desktop"
                                    minDate={currentDate}
                                    maxDate={new Date(currentDate.getFullYear(), 11, 31)}
                                    mask='__/__/____'
                                    value={data}
                                    onChange={(newDate) => setData(newDate)}
                                    renderInput={(params) => <TextField {...params} />}
                                    sx={{
                                        backgroundColor: 'transparent'
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                </DialogContent>
                
                <DialogActions className='dialogactions-default' sx={{paddingX: 4, paddingBottom: 4, justifyContent: 'space-between'}}>
                    <Button
                        type='button'
                        className={`borderradius-default background-grey-3 color-black`}
                        size='small'
                        onClick={setClosedDialog}
                    >
                        Cancelar
                    </Button>
                    
                    <Button
                        type='submit'
                        className='background-yellow color-white borderradius-default'
                        size='small'
                    >
                        Reagendar
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
}

export default DialogActionPlanRescheduleTask;