import React, { useState } from "react";
import { Col, Row } from "reactstrap";

import StoreEvaluation from "./StoreEvaluation";

export default function ComparisonCharts({
  evalStore,
  evalRegional,
  evalComparative,
  storeSacAvg,
  regionalSacAvg,
  sacComparative,
  regions,
  region,
  changeRegion
}) {
  const [activeNav, setActiveNav] = useState(1);

  return (
    <Row className="mt-4">
      <Col className="mb-5 mb-xl-0" xl="12">
        <StoreEvaluation
          activeNav={activeNav}
          onNavChange={setActiveNav}
          regions={regions}
          region={region}
          onRegionChange={changeRegion}
          evalComparative={evalComparative}
          evalRegional={evalRegional}
          evalStore={evalStore}
        />
      </Col>
    </Row>
  );
}
