
import './detailedAssessment.scss';
import React, { useContext, useEffect, useState } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { UserContext } from '../Contexts/UserContext';
import { StoreContext } from '../Contexts/StoreContext';
import RankingService from '../../services/RankingService';
import Loader from '../Bridgestone/Loader';


const DetailedAssessment = () => {
    const { token } = useContext(UserContext);
    const { storeCode, selectedCategory } = useContext(StoreContext);

    const [loading, setLoading] = useState(false);
    const [categoriesAssessment, setCategoriesAssessment] = useState([]);


    useEffect(() => {
        if (!selectedCategory || !storeCode) {
            return;
        }

        setLoading(true);

        RankingService.Details(token, storeCode, selectedCategory)
            .then(resp => {
                setCategoriesAssessment(resp.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [storeCode, selectedCategory]);


    if (!storeCode) {
        return (<></>);
    }


    return (
        <>
            {loading && <Loader />}

            <Grid container className='detailedassessment-container shadow'>
                <Grid item xs={12}>
                    <h6 className="text-uppercase text-light ls-1 mb-1 color-white"> Sua pontuação </h6>
                    <h2 className="mb-0 color-white">Pontuação detalhada no PEB</h2>
                </Grid>

                <Grid item xs={12} marginY={2}>
                    <Divider sx={{borderColor: '#fff'}} />
                </Grid>

                <Grid item xs={12}>
                    <Grid container>
                        {
                            categoriesAssessment.map((categorie, index) => {
                                return (
                                    <Grid item className='categorie-item' key={index}>
                                        <Typography 
                                            variant='caption' 
                                            component='h6' 
                                            fontSize={10}
                                            className={`${index == 0 ? 'color-primary' : ''}`}
                                        >
                                            {categorie.name}
                                        </Typography>
                                        
                                        <Typography 
                                            variant='h4' 
                                            className={`mt-1 fontweight-600 ${index == 0 ? 'color-primary' : ''}`}
                                        >
                                            {categorie.value || 0}
                                        </Typography>
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default DetailedAssessment;