import React from "react";
import { Alert, Container, Row, Col, Spinner } from "reactstrap";
import { Redirect } from "react-router-dom";
import axios from "axios";
import baseURL from "variables/api";
import Loader from "components/Bridgestone/Loader.jsx";

class Submit extends React.Component {
  state = {
    sending: true,
    success: false,
  };

  componentWillMount() {
    this.setState({
      submit: this.props.location.state.submit,
      attachments: this.props.location.state.attachments,
      isSelfEval: this.props.location.state.isSelfEval,
      selfUserId: this.props.location.state.selfUserId,
      isEdit: this.props.location.state.isEdit,
      isAudit: this.props.location.state.isAudit,
      submitIdEdit: this.props.location.state.submitIdEdit,
      submitIdSave: this.props.location.state.submitIdSave,
      submitIdAudit: this.props.location.state.submitIdAudit,
    });
  }

  async componentDidMount() {
    try {
      let data = new FormData();

      let submit = this.state.submit;

      if (this.state.isSelfEval) {
        submit.submitedBy = {
          id: this.state.selfUserId,
          firstName: "Auto",
          lastName: "Avaliação",
        };
      }

      if (this.state.isEdit) {
        data.append("submitIdEdit", this.state.submitIdEdit);
        submit.submitIdEdit = this.state.submitIdEdit;
      }

      if (this.state.isAudit) {
        submit.submitIdAudit = this.state.submitIdAudit;
      }

      if (this.state.submitIdSave) {
        data.append("submitIdSave", this.state.submitIdSave);
      }

      data.append("submit", JSON.stringify(submit));

      for (let name in this.state.attachments) {
        if (!this.state.attachments[name].startsWith("https://")) {
          console.log("appending new image to form data");
          data.append(name, this.state.attachments[name]);
        }
      }

      let submitResponse;
      if (this.state.isSelfEval) {
        submitResponse = await axios.post(baseURL + "/self-submits", data, {
          headers: {
            Accept: "application/json",
            "Content-Type": `multipart/form-data;`,
          },
        });
      } else {
        submitResponse = await axios.post(baseURL + "/submits", data, {
          headers: {
            Accept: "application/json",
            "Content-Type": `multipart/form-data;`,
            Authorization: "Bearer " + localStorage.getItem("banana"),
          },
        });
      }

      console.log(submitResponse);

      this.setState({ sending: false, success: true });

      setTimeout(() => {
        if (!this.state.isSelfEval)
          this.setState({
            toHome: !this.state.isAudit,
            toAudit: this.state.isAudit,
          });
      }, 3000);
    } catch (err) {
      this.setState({ sending: false, success: false });
      console.error(err);
      if (err.httpCode == 401) {
        localStorage.removeItem("banana");
        this.showAlert(err.message);
        setTimeout(() => {
          this.setState({ toLogin: true });
        }, 4000);
      } else {
        this.showAlert(err.message);
      }
    }
  }

  showAlert(message) {
    this.setState({ error: true, errorText: message });
    setTimeout(() => {
      this.setState({ error: false });
    }, 3000);
  }

  render() {
    if (this.state.toLogin) {
      this.setState({ toLogin: false });
      return (
        <Redirect
          push
          to={{
            pathname: "/auth/login/",
          }}
        />
      );
    }

    if (this.state.toAudit) {
      this.setState({ toAudit: false });
      return (
        <Redirect
          push
          to={{
            pathname: "/admin/audit-form",
          }}
        />
      );
    }

    if (this.state.toHome) {
      this.setState({ toHome: false });
      return (
        <Redirect
          push
          to={{
            pathname: "/admin/submits",
          }}
        />
      );
    }

    return (
      <>
        {this.state.error ? (
          <Alert
            color="danger"
            style={{ position: "fixed", top: "30px", zIndex: 9999 }}
          >
            {this.state.errorText}
          </Alert>
        ) : (
          ""
        )}
        {this.state.loading && <Loader />}
        <div className="pb-8 pt-5 pt-md-8">
          <Container fluid>
            <Row>
              <Col>
                <h1 className="rockwell text-center m-2">
                  {this.state.submit.form.metadata.client.name}
                </h1>
                <h2 className="rockwell text-center m-2">
                  Questionário: {this.state.submit.form.title}
                </h2>
              </Col>
            </Row>

            <br />
            {this.state.sending && (
              <Row>
                <Col className="text-center">
                  <Spinner
                    color="primary"
                    style={{ width: "5rem", height: "5rem" }}
                  />
                  <p className="mt-2">Enviando dados...</p>
                </Col>
              </Row>
            )}
            {!this.state.sending && this.state.success && (
              <>
                <Row>
                  <Col className="text-center">
                    <div className="icon icon-lg text-white icon-shape bg-success rounded-circle shadow">
                      <i className="ni ni-check-bold" />
                    </div>
                    <p className="mt-2">Dados enviados com sucesso!</p>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </div>
      </>
    );
  }
}

export default Submit;
