
import './style.scss';
import React from 'react';
import { IconButton, ImageListItem, Typography } from '@mui/material';
import { Close, ConstructionOutlined, InsertDriveFileOutlined, KeyboardArrowLeft, KeyboardArrowRight, Search } from '@mui/icons-material';
import FileHelper from '../../helpers/FileHelper';


const AttachmentCard = ({
    url,
    name,
    onClick = () => {},
    className = '',
    showPreviewIcon = false,
    onRemove = undefined,
    onPrevItem = undefined,
    onNextItem = undefined
}) => {
    const renderContent = () => {
        const isImage = FileHelper.IsImage(name);
        
        if (isImage) {
            return <img
                src={url}
                alt={name}
                loading="lazy"
            />;
        }

        return <>
            <InsertDriveFileOutlined className='file-icon' />
            <Typography className='file-name'>{name}</Typography>
        </>;
    }


    return (
        <>
            <ImageListItem
                className={`attachmentcard-default ${className}`}
                onClick={(e) => {
                    const elementClassList = e.target.getAttribute('class') || '';
                    if (elementClassList.includes('trigger-arrow-change')) {
                        return;
                    }

                    onClick();
                }}
            >
                {
                    onRemove && (
                        <IconButton className='file-remove' size='small' onClick={() => onRemove()}>
                            <Close fontSize='inherit' />
                        </IconButton>
                    )
                }

                {
                    showPreviewIcon && (
                        <IconButton className='file-preview' size='small'>
                            <Search fontSize='inherit' />
                        </IconButton>
                    )
                }

                {
                    onPrevItem !== undefined && (
                        <IconButton className='arrow-prev trigger-arrow-change' size='small' onClick={() => onPrevItem()}>
                            <KeyboardArrowLeft fontSize='inherit' className='trigger-arrow-change' />
                        </IconButton>
                    )
                }

                {
                    onNextItem !== undefined && (
                        <IconButton className='arrow-next trigger-arrow-change' size='small' onClick={() => onNextItem()}>
                            <KeyboardArrowRight fontSize='inherit' className='trigger-arrow-change' />
                        </IconButton>
                    )
                }

                {
                    renderContent()
                }
            </ImageListItem>
        </>
    );
}

export default AttachmentCard;