import './style.scss';
import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  CardHeader,
  Container,
  Row,
  CardBody,
  Alert
} from "reactstrap";

import { UserContext } from "../../../components/Contexts/UserContext";
import { StoreContext } from "../../../components/Contexts/StoreContext";
import Filters from "../../../components/Filters/Filters";
import { Grid, IconButton, Typography, Menu, MenuItem, ImageList } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import RulesAndLicensesCard from "../../../components/RulesAndLicensesCard/RulesAndLicensesCard";
import DialogRulesAndLicenses from '../../../components/Dialog/DialogRulesAndLicenses';
import Loader from '../../../components/Bridgestone/Loader';
import AttachmentCard from '../../../components/AttachmentCard/AttachmentCard';
import RulesAndLicensesService from '../../../services/RulesAndLicensesService';


const listStatusCards = [
  {
    id: 0,
    showOnPreview: false,
    invalidated: false,
    name: 'Todos',
    color: '',
    description: '',
    buttonText: '',
    buttonColor: '',
    buttonDisabled: true,
    classNames: '',
    expression: (x) => {
      return true;
    }
  }, {
    id: 1,
    showOnPreview: true,
    invalidated: false,
    name: 'Pendentes',
    color: 'red',
    description: 'Tarefas a serem concluídas.',
    buttonText: 'Enviar',
    buttonColor: 'red',
    buttonDisabled: false,
    classNames: '',
    expression: (x) => {
      return x.status === 1;
    }
  }, {
    id: 2,
    showOnPreview: true,
    invalidated: false,
    name: 'Em análise',
    color: 'red',
    description: 'Tarefas já concluídas por você, mas ainda não validadas pelo seu consultor técnico.',
    buttonText: 'Em análise',
    buttonColor: 'grey-2',
    buttonDisabled: true,
    classNames: '',
    expression: (x) => {
      return x.status === 2;
    }
  }, {
    id: 3,
    showOnPreview: true,
    invalidated: false,
    name: 'Validados',
    color: 'green',
    description: 'Tarefas já validadas.',
    buttonText: 'Concluído',
    buttonColor: 'green',
    buttonDisabled: true,
    classNames: '',
    expression: (x) => {
      return x.status === 3;
    }
  }, {
    id: 4,
    showOnPreview: true,
    invalidated: true,
    name: 'Invalidadas',
    color: 'yellow',
    description: 'Tarefas invalidadas pela auditoria. Verifique o motivo na "?" e reenvie o comprovante correto.',
    buttonText: 'Reenviar',
    buttonColor: 'yellow',
    buttonDisabled: false,
    classNames: '',
    expression: (x) => {
      return x.status === 4;
    }
   }
];


export default function RulesAndLicenses({
  containerClass = '',
  showFilters = true,
  showCardHeader = true,
  showExamples = true,
  openCardsReadonly = false
}) {
  const { user, token } = useContext(UserContext);
  const { storeCode, selectedCategory } = useContext(StoreContext);

  const [anchorElStatusCardMenu, setAnchorElStatusCardMenu] = useState(undefined);
  const [statusCardSelecionado, setStatusCardSelecionado] = useState(listStatusCards[0]);

  const [cardSelecionado, setCardSelecionado] = useState(undefined);
  const [listCards, setListCards] = useState([]);
  const listCardsFiltrado = listCards.filter(x => statusCardSelecionado.expression(x));

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    handleRefresh();
  }, [storeCode]);

  const handleRefresh = () => {
    setLoading(true);

    RulesAndLicensesService.List(token, storeCode)
      .then(resp => {
        setListCards(resp.data);
      })
      .catch(err => {
        console.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleError = (message, color = 'danger') => {
    setError({ message, color });

    setTimeout(() => {
      setError(undefined);
    }, 4000);
  }

  return (
    <>
      {loading && <Loader />}
      
      {error && (
        <Alert
          color={error.color}
          style={{ position: "fixed", top: "30px", zIndex: 9999 }}
        >
          {error.message}
        </Alert>
      )}

      <Container fluid className={`rulesandlicenses-container ${containerClass}`}>
        {
          showFilters && (
            <Filters showCategories={false} />
          )
        }

        <Row>
          <div className="col">
            <Card className="shadow borderradius-default">
              {
                showCardHeader && (
                  <CardHeader className="border-0 borderradius-default">
                    <h6 className="text-uppercase text-light ls-1 mb-1"> Sua pontuação </h6>
                    <h2 className="mb-0">Normas e licenças</h2>
                  </CardHeader>
                )
              }

              <CardBody className='paddingtop-none'>
                <Row>
                  <Grid container paddingX={2}>
                    {
                      showExamples && (
                        <Grid item xs={12}>
                          <Grid container spacing={2} padding={4} className='rulesandlicensecard-info-container'>
                            <Grid item lg={4.8} sm={6} xs={12} className='padding-none'>
                              <Typography variant='subtitle1' className='fontweight-600 margin-none' marginBottom={1}>
                                Entenda como funciona o sistema para envio de Normas e Licenças
                              </Typography>
                            </Grid>

                            {
                              listStatusCards.filter(x => x.showOnPreview).map((status, index) => {
                                return (
                                  <Grid item lg={1.8} sm={6} xs={12} className='rulesandlicensecard-info' key={index}>
                                    <RulesAndLicensesCard
                                      color={status.color}
                                      buttonText=''
                                      buttonColor={status.buttonColor}
                                      tooltipInvalid={status.invalidated}
                                      className={`small-icon-invalid ${status.classNames}`}
                                    />

                                    <Typography variant='caption' component='h6' className='fontsize-10' paddingTop={2}>
                                      {status.description}
                                    </Typography>
                                  </Grid>
                                );
                              })
                            }
                          </Grid>
                        </Grid>
                      )
                    }
                    
                    <Grid item xs={12} marginTop={4}>
                      <Grid container>
                        <Grid item md={8} xs={12}>
                          {showCardHeader
                            ? (<h2 className="mb-0 fontweight-400">Envie TODAS as Normas e Licenças abaixo para ganhar <b>250 pontos</b> no questionário técnico.</h2>)
                            : (<h2 className="mb-0">Cards de Normas e Licenças</h2>)
                          }
                        </Grid>

                        <Grid item md={4} xs={12} textAlign='right'>
                          <Typography variant='caption' component='h6'>
                            <span className="fontweight-600">Mostrar: </span>
                            {statusCardSelecionado.name}

                            <IconButton className='background-primary color-white ml-3' size='small' onClick={e => setAnchorElStatusCardMenu(e.currentTarget)}>
                              <KeyboardArrowDown fontSize='small' />
                            </IconButton>
                          </Typography>
                        </Grid>

                        <Grid container className='rulesandlicensecard-list-container' paddingTop={1}>
                          {
                            listCardsFiltrado.map((card, index) => {
                              const statusCard = listStatusCards.find(status => status.id > 0 && status.expression(card));

                              if (!statusCard) {
                                return;
                              }
                              
                              const buttonHandleClick = () => {
                                if (!statusCard.buttonDisabled || openCardsReadonly) {
                                  setCardSelecionado(card);
                                }
                              }


                              let tooltipInvalid = undefined;

                              if (statusCard.invalidated) {
                                tooltipInvalid = (
                                  <>
                                    <Typography variant='subtitle2' className='background-red-2 color-white inforulesandlicenses-title' padding={1}>
                                      DETALHES DA INVALIDAÇÃO
                                    </Typography>
                                        
                                    <Typography variant='subtitle2' className='color-white fontsize-12' paddingX={2.5} paddingY={1.5}>
                                      <span className='fontweight-bold'> Motivo: </span>
                                      <Typography className='fontsize-14'> {card.reason && card.reason.description || '-'} </Typography>    
                                    </Typography>
                                        
                                    {
                                      card.attachments && (
                                        <Typography variant='subtitle2' className='color-white fontsize-12' paddingX={2.5} paddingY={1.5}>
                                          <span className='fontweight-bold'> Comprovantes invalidados: </span>
                                          <ImageList cols={2} className='mt-2'>
                                            {
                                              card.attachments.map(attachment => {
                                                return (
                                                  <AttachmentCard
                                                    url={attachment}
                                                    name={attachment}
                                                    onClick={() => window.open(attachment)}
                                                    showPreviewIcon={true}
                                                  />
                                                );
                                              })
                                            }
                                          </ImageList>
                                        </Typography>
                                      )
                                    }
                                  </>
                                );
                              }

                              return (
                                <Grid item key={index} className='rulesandlicensecard-item'>
                                  <RulesAndLicensesCard
                                    color={statusCard.color}
                                    category={card.category}
                                    title={card.title}
                                    buttonText={
                                      user.profile.id === 6
                                        ? statusCard.buttonText
                                        : "Visualizar"
                                    }
                                    buttonColor={statusCard.buttonColor}
                                    buttonDisabled={statusCard.buttonDisabled && !openCardsReadonly}
                                    buttonHandleClick={buttonHandleClick}
                                    className={`height-full ${statusCard.classNames}`}
                                    tooltipInvalid={tooltipInvalid}
                                  />
                                </Grid>
                              );
                            })
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

      <Menu
        anchorEl={anchorElStatusCardMenu}
        open={Boolean(anchorElStatusCardMenu)}
        onClose={_ => setAnchorElStatusCardMenu(null)}
        PaperProps={{
          className: 'background-primary mt-2',
          sx: {
            paddingY: 1,
            borderRadius: 4
          }
        }}
      >
        {
          listStatusCards.map((status, index) => {
            return (
              <MenuItem
                key={index} 
                onClick={_ => {
                  setStatusCardSelecionado(status);
                  setAnchorElStatusCardMenu(null);
                }}
                style={{
                  backgroundColor: status == statusCardSelecionado ? 'rgba(0, 0, 0, 0.1)' : ''
                }}
              >
                <Typography variant='subtitle2' className='color-white'>
                  {status.name}
                </Typography>
              </MenuItem>
            );
          })
        }
      </Menu>

      <DialogRulesAndLicenses
        isOpen={!!cardSelecionado}
        setClosedDialog={_ => setCardSelecionado(undefined)}
        token={token}
        storeCode={storeCode}
        rulesAndLicenses={cardSelecionado}
        readonly={openCardsReadonly}
        handleRefresh={handleRefresh}
        setLoading={setLoading}
        setError={handleError}
      />
    </>
  );
}
