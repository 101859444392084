import React, { useContext, useEffect, useState } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
// core components
import axios from "axios";

import routes from "../routes.js";
import baseURL from "../variables/api";

import Sidebar from "../components/Sidebar/Sidebar.jsx";

import { UserContext } from "../components/Contexts/UserContext";
import { StoreContext } from "../components/Contexts/StoreContext";
import StoreContainer from "../components/StoreContainer/StoreContainer";
import EventsService from "../services/EventsService";

function Admin(props) {
  const { setUser, setToken, token } = useContext(UserContext);
  const {
    store,
    storeCode,
    selectedCategory,
    setStore,
    setConsolidatedRegions,
    setConsolidatedRegion,
    setStoreContainerOpened,
    loadCategories,
  } = useContext(StoreContext);

  const [userLogged, setUserLogged] = useState(false);
  const [mainContent, setMainContent] = useState();

  const [userRoutes, setUserRoutes] = useState([]);

  // const url = window.location.href;
  useEffect(() => {
    const validSelectedCategory =
      selectedCategory !== undefined && selectedCategory.trim().length > 0;

    if (validSelectedCategory) {
      saveEvent();
    }

    if (store && store.pending === "Y") {
      const { history } = props;
      history.push("/admin/subscription");
    }
  }, [storeCode, selectedCategory, window.location.pathname]);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    props.location.search = "";

    let auth = query.get("auth");

    if (auth != null) {
      localStorage.setItem("banana", auth);
    } else if (localStorage.getItem("banana") == null) {
      const { history } = props;
      history.push("/auth/login");
    }

    (async () => {
      if (userLogged == false) {
        await getUser();
      }
    })();
  }, [false]);

  const saveEvent = () => {
    const pageUrl = window.location.pathname;
    const pageDescription =
      routes.find((r) => `${r.layout}${r.path}` == pageUrl).name || "";

    EventsService.Save(
      2,
      storeCode,
      selectedCategory,
      pageDescription,
      pageUrl
    ).catch((err) => {
      console.error(err);
    });
  };

  async function getUser() {
    try {
      const [userResponse, filtersResponse] = await Promise.all([
        axios.get(baseURL + "/my-user", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("banana"),
          },
        }),
        axios.get(baseURL + "/peb/consolidated-filters", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("banana"),
          },
        }),
      ]);

      let user = userResponse.data.user;
      localStorage.setItem("user", JSON.stringify(user));

      let filteredRoutes = routes.filter(
        (route) =>
          route.hidden !== true &&
          (!route.isAdmin ||
            (route.isAdmin && user.admin.toUpperCase() === "Y")) &&
          route.profilesAllowed &&
          route.profilesAllowed.indexOf(user.profileId) > -1
      ).filter((route) => {
        if (!["Questionário", "Questionários", "Meus envios", "Submit"].includes(route.name))
          return true;

        if ([1, 2, 9].includes(user.profileId)) return true;

        if (user.categories.includes("Mystery Shopper")) return true;

        return false;
      });

      setUserRoutes(filteredRoutes);

      if (user.clients === null || user.clients.length === 0) {
        console.error("Sem lojas inscritas para este usuário");
      }

      if (filtersResponse.data.length > 0) {
        setConsolidatedRegions(filtersResponse.data);
        setConsolidatedRegion(filtersResponse.data[0]);
      } else {
        setConsolidatedRegions([]);
        setStore(user.clients[0]);
      }

      const { history } = props;

      const route = routes.find((route) => {
        let profileAllowed = route.profilesAllowed.includes(user.profileId);
        if (profileAllowed && user.isAdmin === "Y") return true;
        if (profileAllowed && !route.isAdmin) return true;
        return false;
      });

      const pathname = route ? route.layout + route.path : "/admin/dashboard";

      history.push(pathname);
      
      setToken(localStorage.getItem("banana"));
      setUserLogged(true);
      setUser(user);
      loadCategories(user);
    } catch (err) {
      console.error(err);
      const { history } = props;
      history.push('/auth/login');
    }
  }

  function getRoutes(routes) {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  }

  function getBrandText(path) {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name + " - " + selectedCategory;
      }
    }
    return "Brand";
  }

  const setContentRef = (element) => {
    setMainContent(element);
  };

  const imgSrc = require("assets/img/brand/excelencia-logo.png");

  return (
    <>
      {userLogged && (
        <>
          {(!store || (store && store.pending === "N")) && (
            <Sidebar
              {...props}
              routes={userRoutes}
              logo={{
                innerLink: "/admin/dashboard",
                imgSrc,
                imgAlt: "...",
              }}
              setShowStoreContainer={(_) => setStoreContainerOpened(true)}
            />
          )}

          <div className="main-content mt-4" ref={setContentRef}>
            {/* {showNav === true && (
              <AdminNavbar
                {...props}
                storeSelected={storeSelected}
                selectedCategory={selectedCategory}
                brandText={getBrandText(props.location.pathname)}
              />
            )} */}

            <Switch>{getRoutes(userRoutes)}</Switch>
            <div className="mt-4 mb-6"></div>
          </div>

          <StoreContainer />
        </>
      )}
    </>
  );
}

export default Admin;
