import Axios from "axios";
import BaseUrl from "../variables/api";

const ResponseSurvey = (formData) => {
  return Axios.post(`${BaseUrl}/peb/surveys`, Object.fromEntries(formData), {
    withCredentials: true,
  });
};

const List = async (token, storeCode, category) => {
  return Axios.get(`${BaseUrl}/peb/surveys/${storeCode}/${category}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

const Average = async (token, storeCode, category, region, compare) => {
  return Axios.get(`${BaseUrl}/peb/surveys-average`, {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      storeCode: region || storeCode === 0 ? undefined : storeCode,
      category,
      region,
      compare,
    },
  });
};

const Download = async (token, storeCode, category, year) => {
  return Axios.get(
    `${BaseUrl}/peb/surveys-export-all/${category}?year=${year}&code=${storeCode}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
      responseType: "blob",
    }
  );
};

const DownloadAll = async (token, category, year) => {
  return Axios.get(
    `${BaseUrl}/peb/surveys-export-all/${category}?year=${year}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
      responseType: "blob",
    }
  );
};

const SatisfactionService = {
  ResponseSurvey,
  List,
  Average,
  Download,
  DownloadAll,
};

export default SatisfactionService;
