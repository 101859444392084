import React, { PropTypes } from "react";
import Autosuggest from "react-autosuggest";
import "./theme.css";

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function getSuggestions(value, arr) {
  const escapedValue = escapeRegexCharacters(value.trim());

  if (escapedValue === "" || escapedValue.length < 3) {
    return [];
  }

  const regex = new RegExp(escapedValue, "i");

  let result = arr.filter(
    (store) => regex.test(store.name) || regex.test(store.code)
  );

  return result;
}

function getSuggestionValue(suggestion) {
  return `${suggestion.code} - ${suggestion.name} (${
    suggestion.address
      ? `${suggestion.address.city} - ${suggestion.address.state}`
      : ""
  })`;
}

function renderSuggestion(suggestion) {
  return (
    <span>
      {suggestion.code}&nbsp;-&nbsp;{suggestion.name}&nbsp;(
      {suggestion.address
        ? `${suggestion.address.city} - ${suggestion.address.state}`
        : ""}
      )
    </span>
  );
}

class SearchBar extends React.Component {
  constructor() {
    super();

    this.state = {
      value: "",
      suggestions: [],
      suggestionsFiltered: [],
    };
  }

  onChange = (event, { newValue, method }) => {
    this.setState((prev) => ({
      value: newValue,
    }));
  };

  onSuggestionsFetchRequested = ({ value }) => {
    if (!this.state.suggestionsFiltered) {
      this.setState({
        suggestionsFiltered: getSuggestions(value, []),
      });
    } else {
      this.setState({
        suggestionsFiltered: getSuggestions(value, this.state.suggestions),
      });
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestionsFiltered: this.state.suggestions });
  };

  componentDidMount() {
    const {
      suggestions,
      suggestionsFiltered,
      inputProps,
      onSuggestionSelected,
      value,
    } = this.props;

    if (suggestions) this.setState({ suggestions });

    if (suggestionsFiltered) this.setState({ suggestionsFiltered });

    if (value) {
      let client = suggestions.filter((el) => el.code == value)[0];
      if (client != null && client.code != null) {
        this.setState({
          value: `${client.code || ""} - ${client.name || ""} (${
            client.city || ""
          } - ${client.state || ""})`,
        });
      }
    }

    if (onSuggestionSelected)
      this.setState({
        onSuggestionSelected,
      });
  }

  componentWillReceiveProps({ suggestions, suggestionsFiltered, value }) {
    if (suggestions) {
      this.setState({ suggestions });
    }

    if (suggestionsFiltered) {
      this.setState({ suggestionsFiltered });
    }

    if (value) {
      if (value == "clear") {
        let { inputProps } = this.state;

        if (!inputProps) {
          inputProps = {
            placeholder: "Buscar clientes...",
            value: this.state.value,
            onChange: this.onChange,
          };
        } else {
          inputProps.onChange = this.onChange;
        }

        inputProps.value = "";
        this.setState({
          value: "",
          inputProps,
        });
      } else {
        this.setState({
          value: getSuggestionValue(value),
        });
      }
    }
  }

  render() {
    let { suggestionsFiltered, inputProps, onSuggestionSelected } = this.state;

    if (!inputProps) {
      inputProps = {
        placeholder: "Buscar clientes...",
        value: this.state.value,
        onChange: this.onChange,
      };
    } else {
      inputProps.onChange = this.onChange;
    }

    inputProps.value = this.state.value;

    // if (!onSuggestionsClearRequested) {
    //   onSuggestionsClearRequested = this.onSuggestionsClearRequested;
    // }

    // if (!onSuggestionsFetchRequested) {
    //   onSuggestionsFetchRequested = this.onSuggestionsFetchRequested;
    // }
    return (
      <Autosuggest
        suggestions={suggestionsFiltered}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}

export default SearchBar;
