import "./style.scss";
import React, { useState, useContext, useEffect } from "react";
import { Card, CardHeader, Container, Row, CardBody } from "reactstrap";
import axios from "axios";
import baseURL from "../../../variables/api";

import { UserContext } from "../../../components/Contexts/UserContext";
import { StoreContext } from "../../../components/Contexts/StoreContext";
import Filters from "../../../components/Filters/Filters";
import { Grid, Divider, Box } from "@mui/material";
import EmblemCard from "../../../components/EmblemCard/EmblemCard";
import EmblemsService from "../../../services/EmblemsService";
import Loader from "../../../components/Bridgestone/Loader";

export default function YourEmblems({
  containerClass = '',
  showFilters = true,
}) {
  const { token } = useContext(UserContext);
  const { storeCode, selectedCategory } = useContext(StoreContext);

  const [listEmblemas, setListEmblemas] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!selectedCategory) {
      return;
    }

    setLoading(true);

    EmblemsService.List(token, storeCode, selectedCategory)
      .then((resp) => {
        setListEmblemas(resp.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedCategory, storeCode]);

  return (
    <>
      {loading && <Loader />}

      <Container fluid className={`youremblems-container ${containerClass}`}>
        {showFilters && <Filters />}

        <Row>
          <div className="col">
            <Card className="shadow borderradius-default">
              <CardHeader className="border-0 borderradius-default">
                <h6 className="text-uppercase text-light ls-1 mb-1">
                  {" "}
                  Sua pontuação{" "}
                </h6>
                <h2 className="mb-0">Seus emblemas</h2>

                <Box marginTop={2}>
                  <Divider />
                </Box>
              </CardHeader>

              <CardBody className="paddingtop-none">
                <Row>
                  <Grid container paddingX={2}>
                    {listEmblemas.map((emblema, index) => {
                      return (
                        <Grid item key={index} className="youremblemcard-item">
                          <EmblemCard
                            title={emblema.title}
                            image={emblema.image}
                            active={emblema.active}
                            description={emblema.description}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
